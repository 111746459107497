import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sha1 from 'sha1';
import { usePopper } from 'react-popper';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope, faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import { TrackButton, ErrorKeyCustomized } from '../../track';
import { googleIcon } from '../../components/Misc/Icons';
import { signUpWithGoogle } from '../../redux/actions/auth.actions';
import Input from '../../components/atoms/Input/Input';
import SCNewSignUp, { PopperContainer } from './NewSignUp.style';
import Button from '../../components/atoms/Button/Button';
import { toBase64 } from '../../utils/functions/base64';
import RegionBanner from '../../components/molecules/RegionBanner/RegionBanner';
import useInternationalization from '../../hooks/Internationalization/useInternationalization';
import { isSantanderEnvironment } from '../../utils/locale';
import getLegalDocumentsUrls from '../../utils/internationalizationModules/legalDocuments';

const NewSignUp = ({
  setAccount,
  currentUser,
  userEmail,
  account,
  auth,
  hideRegionBanner,
}) => {
  const i18n = useTranslation();

  const [password, setPassword] = useState(account.password);
  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  const [email, setEmail] = useState(userEmail);
  const [alreadyRegistered, setAlreadyRegistered] = useState();
  const [domainBlacklisted, setDomainBlacklisted] = useState();

  const reg = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
  const regLength = new RegExp('^(?=.{8,})');
  const regNumber = new RegExp('^(?=.*[0-9])');
  const regCapital = new RegExp('^(?=.*[A-Z])');

  const [showPasswordValidator, setShowPasswordValidator] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'arrow', options: { element: null } },
      { name: 'offset', options: { offset: [0, 10] } },
    ],
    hide: {
      enabled: false,
    },
    preventOverflow: {
      enabled: false,
    },
  });
  const { currentLocale } = useInternationalization();
  const { privacyPolicy, generalConditions } = getLegalDocumentsUrls(
    i18n.getLanguage()
  );

  useEffect(() => {
    setEmail(userEmail);
    setAlreadyRegistered(false);
    setFirstName(account.firstName);
    setLastName(account.lastName);
  }, [userEmail, account.firstName, account.lastName]);

  useEffect(() => {
    const cook = document.cookie.split(';');
    const cookieEmail = cook.find(
      (item) => item.indexOf('captureEmailCookie=') > -1
    );
    if (cookieEmail) {
      const em = cookieEmail.substr(
        cook
          .find((item) => item.indexOf('captureEmailCookie=') > -1)
          .indexOf('captureEmailCookie=') + 'captureEmailCookie='.length,
        cook.find((item) => item.indexOf('captureEmailCookie=') > -1).length
      );
      if (em) {
        const getName = em.split('@')[0];
        if (getName && getName.indexOf('.') > -1) {
          const names = getName.split('.');
          if (names[0].length > 1 && names[1].length > 1) {
            setFirstName(names[0]);
            setLastName(names[1]);
          }
        }
        setEmail(em);
        axios.post('/api/capture-cookie-email', { email: em });
        document.cookie =
          'captureEmailCookie=;expires=Fri, 31 Dec 1990 23:59:59 GMT;domain=.bewica.com;path=/';
      }
    }
  }, []);

  const re = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const checkAlreadyRegistered = (e) => {
    const { value } = e.target;
    if (!value) return;
    if (!re.test(value)) {
      ErrorKeyCustomized({
        i18nKey: 'errors.wrongEmailFormat',
        label: 'checkAlreadyRegistered',
      });
      return;
    }
    if (alreadyRegistered && auth && !auth.currentUser) {
      ErrorKeyCustomized({
        i18nKey: 'errors.alreadyRegistered',
        label: 'checkAlreadyRegistered',
      });
      return;
    }
    axios
      .get(`/email/${toBase64(value)}`)
      .then((res) => {
        if (res && res.data && res.data.code === 'auth/user-exists') {
          setAlreadyRegistered(true);
        } else {
          setAlreadyRegistered(false);
        }
        if (res && res.data && res.data.code === 'auth/domain-blacklisted') {
          setDomainBlacklisted(true);
        } else {
          setDomainBlacklisted(false);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        console.log(error.data);
      });
  };

  const checkPwnedPassword = async (text) => {
    let pwned = false;
    const digit5 = sha1(text).substring(0, 5);
    const checkRest = sha1(text).replace(digit5, '');
    await axios
      .get(`https://api.pwnedpasswords.com/range/${digit5}`)
      .then((res) => {
        const pass = res.data.split('\r\n');
        // check each row of array with hash passwords and see if user's password exists
        for (let i = 0; i < pass.length; i += 1) {
          if (pass[i].indexOf(checkRest.toUpperCase()) > -1) {
            // oops password has been pwned!
            pwned = true;
            break;
          }
        }
      });
    return pwned;
  };

  const nextStep = async () => {
    if (!currentUser) {
      if (alreadyRegistered) {
        ErrorKeyCustomized({
          i18nKey: 'errors.alreadyRegistered',
          label: 'nextStep',
        });
        return;
      }
      if (!re.test(email)) {
        ErrorKeyCustomized({
          i18nKey: 'errors.wrongEmailFormat',
          label: 'nextStep',
        });
        return;
      }
      if (!reg.test(password)) {
        ErrorKeyCustomized({
          i18nKey: 'errors.wrongPasswordFormat',
          label: 'nextStep',
        });
        return;
      }

      if (domainBlacklisted) {
        ErrorKeyCustomized({
          i18nKey: 'errors.domainBlacklisted',
          label: 'nextStep',
        });
        return;
      }
      if (password) {
        const pwned = await checkPwnedPassword(password);
        if (pwned) {
          ErrorKeyCustomized({
            i18nKey: 'errors.pwnedPassword',
            label: 'nextStep',
          });
          return;
        }
      }
      if (password && firstName && lastName && email) {
        setAccount({
          firstName,
          lastName,
          password,
          email: email.trim().toLowerCase(),
          createNewAccount: true,
        });
        TrackButton('setup.stepOne.nextButton');
      } else {
        ErrorKeyCustomized({
          i18nKey: 'errors.signUpEmptyFields',
          label: 'nextStep1',
        });
      }
    } else if (firstName && lastName && email) {
      setAccount({
        email: email.trim().toLowerCase(),
        firstName,
        lastName,
        createNewAccount: true,
      });
    } else {
      ErrorKeyCustomized({
        i18nKey: 'errors.signUpEmptyFields',
        label: 'nextStep2',
      });
    }
  };

  const editEmail = (value) => {
    setAlreadyRegistered(false);
    setEmail(value);
  };

  const handleSignUpWithGoogle = async () => {
    const googlePayload = await signUpWithGoogle();

    setAccount({
      ...googlePayload,
      signUpWithGoogle: true,
      createNewAccount: true,
    });
  };

  return (
    <SCNewSignUp>
      <h1>{i18n.t('setup.welcome')}</h1>

      {!hideRegionBanner && <RegionBanner />}

      {!isSantanderEnvironment && (
        <div className="NewSignUp_Buttons">
          <div className="NewSignUp_GoogleButton">
            <Button
              text={
                <>
                  {googleIcon}
                  {i18n.t('setup.account.signUpWithGoogle')}
                </>
              }
              onClick={() => handleSignUpWithGoogle()}
              size="full"
              color="white"
            />
          </div>
        </div>
      )}

      <div>
        <div className="nameHolder fs-exclude" style={{ display: 'flex' }}>
          <div
            className="inputGroup"
            style={{
              color: 'black',
              marginRight: '10px',
              textAlign: 'left',
              opacity: firstName ? 1 : 0.4,
            }}>
            <Input
              inputType="text"
              size="large"
              icon={faUser}
              inputPlaceholder={i18n.t('setup.account.firstNameEg')}
              defaultValue={firstName || ''}
              onChangeValue={(val) => {
                setFirstName(val);
              }}
              name="firstName"
              id="firstName"
            />
          </div>

          <div
            className="inputGroup"
            style={{
              color: 'black',
              marginRight: 0,
              textAlign: 'left',
              opacity: lastName ? 1 : 0.4,
            }}>
            <Input
              inputType="text"
              size="large"
              icon={faUser}
              inputPlaceholder={i18n.t('setup.account.lastNameEg')}
              defaultValue={lastName || ''}
              onChangeValue={(val) => {
                setLastName(val);
              }}
              name="lastName"
              id="lastName"
            />
          </div>
        </div>

        <div
          className="inputGroup fs-exclude"
          style={{
            marginTop: '17px',
            color: 'black',
            textAlign: 'left',
            opacity: email ? 1 : 0.4,
          }}>
          <Input
            inputType="text"
            size="large"
            icon={faEnvelope}
            inputPlaceholder={i18n.t('setup.account.emailEg')}
            defaultValue={email || ''}
            onChangeValue={(val) => {
              editEmail(val);
            }}
            name="email"
            id="email"
            onBlur={(e) => checkAlreadyRegistered(e)}
            disabled={userEmail && auth && auth.currentUser}
          />
        </div>

        {!currentUser && (
          <div
            className="passwordHolder fs-exclude"
            style={{ marginTop: '17px' }}>
            <div
              className="inputGroup"
              style={{
                color: 'black',
                textAlign: 'left',
                marginTop: 0,
              }}>
              <div className="inputWithIcon">
                <Input
                  inputType="password"
                  size="large"
                  icon={faKey}
                  inputPlaceholder={i18n.t('setup.account.passwordEg')}
                  defaultValue={password || ''}
                  onChangeValue={(val) => {
                    setPassword(val.trim());
                  }}
                  name="password"
                  id="password"
                  Internalref={setReferenceElement}
                  onFocus={() => setShowPasswordValidator(true)}
                  onBlur={() => setShowPasswordValidator(false)}
                  style={{
                    width: '100%',
                    marginTop: 0,
                    opacity: password ? 1 : 0.4,
                    padding: '0.55rem 2rem',
                  }}
                />
              </div>
            </div>
            {showPasswordValidator && (
              <PopperContainer ref={setPopperElement} style={styles.popper}>
                <h2>{i18n.t('setup.getAccess.passwordPolicy')}</h2>

                {regLength.test(password) && password && password.length > 0 ? (
                  <div style={{ color: '#75B531' }}>
                    <FontAwesomeIcon icon={faCheck} />{' '}
                    {i18n.t('setup.getAccess.passwordLength')}
                  </div>
                ) : (
                  <div>{i18n.t('setup.getAccess.passwordLength')}</div>
                )}

                {regNumber.test(password) ? (
                  <div style={{ color: '#75B531' }}>
                    <FontAwesomeIcon icon={faCheck} />{' '}
                    {i18n.t('setup.getAccess.includeNumber')}
                  </div>
                ) : (
                  <div>{i18n.t('setup.getAccess.includeNumber')}</div>
                )}

                {regCapital.test(password) ? (
                  <div style={{ color: '#75B531' }}>
                    <FontAwesomeIcon icon={faCheck} />{' '}
                    {i18n.t('setup.getAccess.includeCapital')}
                  </div>
                ) : (
                  <div>{i18n.t('setup.getAccess.includeCapital')}</div>
                )}
              </PopperContainer>
            )}
          </div>
        )}
      </div>

      <div className="terms-and-conditions-container">
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('setup.account.acceptTerms1', {
              generalTermsAndConditions: `<a href=${generalConditions} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.generalTermsAndConditions'
              )}</a>`,
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.privacyPolicy'
              )}</a>`,
            }),
          }}
        />
        <p>{i18n.t('setup.account.acceptTerms2')}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: i18n.t('setup.account.acceptTerms3', {
              privacyPolicy: `<a href=${privacyPolicy} target="_blank" rel="noopener noreferrer">${i18n.t(
                'setup.account.here'
              )}</a>`,
            }),
          }}
        />
      </div>

      <div className="NewSignUp_RegisterButton">
        <Button
          id="registerButton"
          text={i18n.t('setup.account.createAccount')}
          onClick={() => {
            nextStep();
            TrackButton('setup.account.createFreeAccount');
          }}
          size="full"
          color="red"
        />
        <Link to="/sign-in">{i18n.t('setup.account.iHaveAccount')}</Link>
      </div>
    </SCNewSignUp>
  );
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  // client: state.client,
  // monitoredDomains: state.monitoredDomains,
});

export default connect(mapStatetoProps)(NewSignUp);
