import styled from '@emotion/styled';

const SCSignUp = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export default SCSignUp;
