import React from 'react';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../i18n';
import SCWebsiteSecurityCard from './WebsiteSecurityCard.style';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import {
  getSecurityColor,
  getSecurityText,
} from '../../../utils/functions/securitySeverity';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';
import { parseDate } from '../../../utils/format/dataFormat';

const WebsiteSecurityCard = ({ data = {}, isHeaderClickable, loading }) => {
  const navigate = useNavigate();
  const i18n = useTranslation();

  const { updatedAt, protectionScore = {}, mainMonitoredDomain } = data;

  const websiteData = protectionScore[mainMonitoredDomain] ?? {};
  const { mitm, clickJacking, xss, ddos } = websiteData;

  const LABEL_DETAILS = [
    {
      name: i18n.t('controlPanel.websiteSecurity.intermediaryAttacks'),
      value: i18n.t(
        `websiteSecurity.${mitm ? getSecurityText(mitm) : 'unknown'}`
      ),
      color: getSecurityColor(mitm),
    },
    {
      name: i18n.t('controlPanel.websiteSecurity.clickjackingAttacks'),
      value: i18n.t(
        `websiteSecurity.${
          clickJacking ? getSecurityText(clickJacking) : 'unknown'
        }`
      ),
      color: getSecurityColor(clickJacking),
    },
    {
      name: i18n.t('controlPanel.websiteSecurity.crossSiteScripting'),
      value: i18n.t(
        `websiteSecurity.${xss ? getSecurityText(xss) : 'unknown'}`
      ),
      color: getSecurityColor(xss),
    },
    {
      name: i18n.t('controlPanel.websiteSecurity.ddosAttacks'),
      value: i18n.t(
        `websiteSecurity.${ddos ? getSecurityText(ddos) : 'unknown'}`
      ),
      color: getSecurityColor(ddos),
    },
  ];

  const handleHeaderButtonOnClick = isHeaderClickable
    ? () => {
        navigate('/client/websitesecurity');
      }
    : undefined;

  return (
    <SCWebsiteSecurityCard>
      <SectionCard
        headerIcon={faGlobe}
        headerTitle={`${i18n.t(
          'controlPanel.websiteSecurity.title'
        )} ${mainMonitoredDomain}`}
        headerButtonOnClick={handleHeaderButtonOnClick}>
        <LabelDetailGroup
          labelDetails={LABEL_DETAILS}
          lastUpdateDate={parseDate(updatedAt)}
          loading={loading}
        />
      </SectionCard>
    </SCWebsiteSecurityCard>
  );
};
export default WebsiteSecurityCard;
